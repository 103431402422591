import { render, staticRenderFns } from "./articles.vue?vue&type=template&id=daacfe06&scoped=true&"
import script from "./articles.vue?vue&type=script&lang=js&"
export * from "./articles.vue?vue&type=script&lang=js&"
import style0 from "./articles.vue?vue&type=style&index=0&id=daacfe06&lang=scss&scoped=true&"
import style1 from "./articles.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./articles.vue?vue&type=style&index=2&id=daacfe06&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daacfe06",
  null
  
)

export default component.exports